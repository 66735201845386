<template>
    <div class="introduce">
        <div class="agreement-msg" v-if="!showAgreement" >
            <template v-if="empower_agreement">
                <div class="title">请选择签署协议</div>
                <div class="desc">
                    <van-radio-group class="agree-group" v-model="agreement_type" :disabled="agreementDisabled">
                        <van-cell-group>
                            <van-cell :class="{'active': agreement_type == 'doctor'}" title="医生劳务协议" clickable @click="selectAgree('doctor')">
                            <template #icon>
                                <van-radio name="doctor" shape="square" class="radio-area" />
                            </template>
                            </van-cell>
                            <van-cell :class="{'active': agreement_type == 'empower'}" title="志愿者协议" clickable @click="selectAgree('empower')">
                            <template #icon>
                                <van-radio name="empower" shape="square" class="radio-area" />
                            </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
                <div class="btn">
                    <van-button round type="info" size="large" class="up" @click="changeAgreement(agreement_type)">确认</van-button>
                </div>
            </template>
        </div>
        <template v-else>
            <div id="demo"></div>
            <div class="btn">
                <van-button round type="info" size="large" class="up" @click="next">点击签名</van-button>
            </div>
        </template>
        <div class="goBack" @click="back">
            <img src="/static/goBack.png" />
        </div>
    </div>
</template>

<script>
    import {
        Dialog
    } from "vant";
    import Pdfh5 from "pdfh5";
    import "pdfh5/css/pdfh5.css"
    import {
        getSubProjectAgreement,
        subschemeAgre,
        schemeAgre
    } from '@/api/api'
    import {
        mapActions,
        mapGetters,
        mapState
    } from 'vuex';
    import {
        appHost
    } from '@/utils/host'
    import {parseUrl} from '@/utils/utils'
    export default {
        data() {
            return {
                project_doctor_id: null,
                baseFileUrl: appHost,
                pdfh5: null,
                empower_agreement: '', // 志愿者协议
                service_agreement: '', // 医生劳务协议
                agreement_type: null,
                agreementDisabled: false,
                showAgreement: false,
                is_empower: 0, // 是否志愿者协议  0：否；1：是
            }
        },
        mounted() {
            this.init()
        },
        computed: {
            ...mapGetters(['project_id']),
            ...mapState(['userId'])
        },
        methods: {
            changeAgreement(e) {
                console.log('changeAgreement', e)
                if (this.agreementDisabled) return
                this.agreementDisabled = true
                let agreement = ''
                if (this.agreement_type == 'empower') {
                    agreement = this.empower_agreement
                    this.is_empower = 1
                } else {
                    agreement = this.service_agreement
                    this.is_empower = 0
                }
                this.createPdf(agreement)
            },
            async init() {
                let res = null
                if (this.$route.query.subscheme_id && this.$route.query.subscheme_id > 0) {
                    res = await subschemeAgre({
                        params: {
                            id: this.$route.query.subscheme_id,
                            doctor_id: this.userId
                        }
                    })
                    this.empower_agreement = res.empower_agreement
                    this.service_agreement = res.service_agreement
                    if (this.empower_agreement) {
                        if (!this.service_agreement) {
                            this.is_empower = 1
                            this.createPdf(res.empower_agreement)
                        }
                    } else {
                        this.createPdf(res.service_agreement)
                    }
                } else {
                    res = await schemeAgre({
                        params: {
                            id: this.$route.query.scheme_id,
                            doctor_id: this.userId
                        }
                    })
                    this.createPdf(res.service_agreement)
                }
            },
            createPdf(val) {
                this.showAgreement = true
                this.agreementDisabled = false
                this.$nextTick(()=>{
                    this.pdfh5 = new Pdfh5("#demo", {
                        pdfurl: parseUrl(val, this.baseFileUrl)
                    });
                    //监听完成事件
                    this.pdfh5.on("complete", function (status, msg, time) {
                        console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
                    })
                })
            },
            selectAgree(val) {
                this.agreement_type = val
                const self = this
                if (val == 'empower') {
                    Dialog.alert({
                        title: '签署协议须知',
                        message: '尊敬的医生您好，感谢您对公益调研工作的支持，您目前所选择签署志愿者合同，签署后您将作为志愿者为本项目统筹上传病例信息资料，志愿者无任何劳务报酬。\n \n <span style="color: #999;">如确认签署本合同请点击“是”，或点击“返回”重新选择</span>',
                        theme: 'round-button',
                        showCancelButton: true,
                        confirmButtonColor: '#4B99FF',
                        confirmButtonText: '是',
                        cancelButtonText: '返回',
                        cancelButtonColor: '#ccc',
                        overlayClass: "back_class",
                        messageAlign: "left"
                    }).then(() => {
                        this.is_empower = 1
                        this.createPdf(this.empower_agreement)
                    }).catch(() => {
                        this.agreement_type = null
                    });
                }
            },
            next() {
                this.$router.replace({
                    path: '/case_sign',
                    query: {
                        scheme_id: this.$route.query.scheme_id,
                        subscheme_id: this.$route.query.subscheme_id,
                        phase: this.$route.query.phase ? this.$route.query.phase : 1,
                        record_id: this.$route.query.record_id ? this.$route.query.record_id : '',
                        is_empower: this.is_empower,
                        is_resign: this.$route.query.is_resign, //判断是否要重新签署医生劳务协议
                    }
                })
            },
            back() {
                if (this.showAgreement && this.service_agreement && this.empower_agreement) {
                    this.$router.go(0);
                    return
                }
                this.$router.replace({
                    path: '/case',
                    query: {
                        scheme_id: this.$route.query.scheme_id,
                        subscheme_id: this.$route.query.subscheme_id,
                        phase: this.$route.query.phase ? this.$route.query.phase : 1,
                        record_id: this.$route.query.record_id ? this.$route.query.record_id : '',
                        steps: 0,
                    }
                })
            }
        }

    }
</script>

<style lang="scss" scoped>
    .introduce {
        height: 100%;
        width: 100%;
        .back_class{
            color: #4B99FF;
        }
        .text {
            padding: 10px;
            box-sizing: border-box;
            padding-bottom: constant(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom);
            margin-bottom: 70px;
        }
        .agreement-msg {
            text-align: center; margin: 30px 20%; overflow: hidden;
            .title {
                font-size: 20px; color: #000; margin-bottom: 35px;
            }
            .desc {
                text-align: left;
            }
            .radio-area {
                margin-right: 10px;
            }
        }
    }
</style>
<style lang="scss" scoped>
.introduce {
    // /deep/.van-button__text{
    //     color: #4B99FF !important;
    // }
    
    .agree-group {
        .van-cell-group {
            &::after {display: none;}
        }
        .radio-area { display: none; }
        .van-cell {
            margin-bottom: 20px; background: #f6f6f6; text-align: center; font-size: 16px; line-height: 2.4; border-radius: 5px;
            &::after {display: none;}
            &.active {
                background: #F2F6FF; box-shadow: inset 0 0 0 1px #406EDB;
            }
        }
    }
}
</style>